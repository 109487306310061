<template>
  <validation-observer
    #default="{ handleSubmit, invalid, pristine }"
    ref="refFormObserver"
  >
    <IAmOverlay :loading="loading">
      <!-- SECTION Form -->
      <b-form @submit.prevent="handleSubmit(nextHandle)">
        <!-- SECTION Card agency basic info -->
        <b-card
          header-tag="header"
          border-variant="info"
          header-bg-variant="light-info"
          header-class="py-1"
          class="border mt-1"
        >
          <!-- ANCHOR header -->
          <template #header>
            <div class="d-flex align-items-center justify-content-between w-100">
              <h6 class="m-0">
                {{ !promotion.id ? $t('promotionAg.create.title'): readonly ? $t('promotionAg.edit.title') : $t('promotionAg.edit.titleEdit') }}
                <b-badge
                  v-if="promotion.code"
                  variant="primary"
                  class="py-50"
                >
                  {{ promotion.code }}
                </b-badge>
              </h6>
              <b-button
                v-if="readonly && !!promotion.id && canEdit"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="py-75"
                :disabled="!canAccess('customer.updateCustomer')"
                @click="handleEdit"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                  class="mr-50"
                />
                <span class="align-middle">{{ $t('customer.edit') }}</span>
              </b-button>
              <b-button
                v-if="!readonly && !!promotion.id"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="py-75"
                :disabled="!canAccess('customer.updateCustomer')"
                @click="handleCancelEdit"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                <span class="align-middle">{{ $t('customer.cancel') }}</span>
              </b-button>
            </div>
          </template>
          <b-card-body class="p-0 pt-2">
            <b-row>
              <!-- ANCHOR Code -->
              <!-- <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAg.code')"
                  rules="code|length:9"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.code') }}
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotion.code"
                      maxlength="9"
                      minlength="9"
                      lazy-formatter
                      :disabled="true"
                      :formatter="upperCaseFormatter"
                      :placeholder="$t('promotionAg.placeholder.code')"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col> -->

              <!-- ANCHOR Name -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAg.name')"
                  rules=""
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.name') }}
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotion.name"
                      maxlength="100"
                      lazy-formatter
                      :disabled="readonly"
                      :formatter="trimInput"
                      :placeholder="$t('promotionAg.placeholder.name')"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR promotionType -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.promotionType') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotion.paidType"
                    style="font-size: 1rem;"
                    :options="typeOfDiscountPaidOptions"
                    :clearable="false"
                    :disabled="readonly"
                    :reduce="val => val.value"
                  >
                    <template #option="data">
                      <span>
                        {{ $t(`promotionAg.${data.label}`) }}
                      </span>
                    </template>

                    <template #selected-option="data">
                      <span>
                        {{ $t(`promotionAg.${data.label}`) }}
                      </span>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- ANCHOR promotionType -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.promotionStrategy') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotion.strategy"
                    style="font-size: 1rem;"
                    :options="typeOfDiscountStrategyOptions"
                    :clearable="false"
                    :disabled="readonly"
                    label="label"
                    :reduce="val => val.value"
                  >
                    <template #option="data">
                      <span>
                        {{ $t(`promotionAg.${data.label}`) }}
                      </span>
                    </template>

                    <template #selected-option="data">
                      <span>
                        {{ $t(`promotionAg.${data.label}`) }}
                      </span>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- ANCHOR Discount -->
              <b-col md="4">
                <b-row>
                  <!-- ANCHOR discountAmount -->
                  <b-col
                    v-if="promotion.discountType === 'VND'"
                    style="min-width: 20%;"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('promotionAg.discount')"
                      rules="required"
                    >
                      <b-form-group>
                        <template #label>
                          <div>
                            {{ $t('promotionAg.discount') }}
                            <span class="text-danger">(*)</span>
                          </div>
                        </template>
                        <!-- v-remove-non-numeric-chars.allNumber -->
                        <b-form-input
                          v-model="promotion.discountAmount"
                          type="number"
                          number
                          :state="getValidationState(validationContext) === false ? false : null"
                          maxlength="30"
                          :disabled="readonly"
                          lazy-formatter
                          :formatter="trimInput"
                          :placeholder="$t('promotionAg.placeholder.discountAmount')"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR discountPercent -->
                  <b-col
                    v-else
                    style="min-width: 20%;"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('promotionAg.discount')"
                      rules="required|maxValue:100|min_value:0"
                    >
                      <b-form-group>
                        <template #label>
                          <div>
                            {{ $t('promotionAg.discount') }}
                            <span class="text-danger">(*)</span>
                          </div>
                        </template>
                        <!-- v-remove-non-numeric-chars.allow.trim-after-percent -->
                        <b-form-input
                          v-model="promotion.discountPercent"
                          max="100"
                          type="number"
                          number
                          step="0.01"
                          min="0"
                          :state="getValidationState(validationContext) === false ? false : null"
                          :placeholder="$t('promotionAg.placeholder.discountPercent')"
                          :disabled="readonly"
                          lazy-formatter
                          :formatter="trimInput"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR discountType -->
                  <b-col
                    cols="4"
                    class="pl-0"
                  >
                    <b-form-group>
                      <template #label>
                        <div style="opacity: 0;">
                          {{ "-" }}
                        </div>
                      </template>
                      <v-select
                        v-model="promotion.discountType"
                        style="font-size: 1rem;"
                        :options="typeOfDiscountOptions"
                        :disabled="readonly"
                        :clearable="false"
                        :reduce="val => val.value"
                        @input="handleChangeDiscountType"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

              <!-- ANCHOR Start date -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAg.startTime')"
                  rules="required"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.startTime') }}
                        <span class="text-danger">(*)</span>
                      </div>
                    </template>
                    <b-form-datepicker
                      v-model="promotion.startTime"
                      :locale="$i18n.locale"
                      :placeholder="$t('placeholderSelect')"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      :min="new Date()"
                      :max="promotion.endTime ? new Date(promotion.endTime) : null"
                      :disabled="readonly"
                      show-decade-nav
                      hide-header
                      boundary="window"
                      no-flip
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR End date -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAg.endTime')"
                  rules="required"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.endTime') }}
                        <span class="text-danger">(*)</span>
                      </div>
                    </template>
                    <b-form-datepicker
                      v-model="promotion.endTime"
                      :locale="$i18n.locale"
                      :placeholder="$t('placeholderSelect')"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      :min="promotion.startTime ? new Date(promotion.startTime) : new Date()"
                      show-decade-nav
                      hide-header
                      :disabled="readonly"
                      boundary="window"
                      no-flip
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row class="mt-50 mb-2">
              <b-col
                sm="12"
                style="border-top: 4px dotted silver;"
              />
            </b-row>

            <b-row>
              <!-- ANCHOR minimum Passenger -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAg.minimumPassenger')"
                  rules=""
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.minimumPassenger') }}
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotion.minimumPassenger"
                      v-remove-non-numeric-chars.allNumber
                      :disabled="readonly"
                      number
                      maxlength="5"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('promotionAg.placeholder.minimumPassenger')"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Quota -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.quotaAmount') }}
                    </div>
                  </template>
                  <b-form-input
                    v-model="promotion.quotaAmount"
                    v-remove-non-numeric-chars.allNumber
                    number
                    type="number"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('promotionAg.placeholder.quotaAmount')"
                    :disabled="readonly"
                  />
                </b-form-group>
              </b-col>

              <!-- ANCHOR quotaCounter -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.quotaCounter') }}
                    </div>
                  </template>
                  <b-form-input
                    v-model="promotion.quotaCounter"
                    v-remove-non-numeric-chars.allNumber
                    type="number"
                    number
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('promotionAg.placeholder.quotaCounter')"
                    :disabled="readonly"
                  />
                </b-form-group>
              </b-col>

              <!-- ANCHOR Airline -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.airline') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotion.airline"
                    style="font-size: 1rem;"
                    :options="airlineFilterOptionsWithAllAirlines"
                    multiple
                    :close-on-select="false"
                    :reduce="val => val.value"
                    :placeholder="$t('promotionAg.allAirlines')"
                    class="v-select-wrap"
                    :disabled="readonly"
                  >
                    <template #selected-option="data">
                      <div class="d-flex-center justify-content-between">
                        <span class="d-block text-nowrap">
                          {{ data.value ? data.label : $t(`promotionAg.${data.label}`) }}
                        </span>
                      </div>
                    </template>
                    <template #option="data">
                      <div class="d-flex-center justify-content-between">
                        <span class=" d-block text-nowrap">
                          {{ data.value ? data.label : $t(`promotionAg.${data.label}`) }}
                        </span>
                      </div>
                    </template>
                    <template #spinner="data">
                      <div
                        v-if="data.loading"
                        style="border-left-color: rgba(88, 151, 251, 0.71)"
                        class="vs__spinner"
                      />
                    </template>
                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- ANCHOR Source -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.source') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotion.source"
                    style="font-size: 1rem;"
                    :options="sourceFilterOptionsWithAllSources"
                    multiple
                    :close-on-select="false"
                    :reduce="val => val.value"
                    :loading="loadingSources"
                    :placeholder="$t('promotionAg.allAirlines')"
                    class="v-select-wrap"
                    :disabled="readonly"
                  >
                    <template #selected-option="data">
                      <div class="d-flex-center justify-content-between">
                        <span class="d-block text-nowrap">
                          {{ data.value
                            ? ($te(`flight.airlines.${data.label}`)
                              ? `${$t(`flight.airlines.${data.label}`)} (${$t(`flight.sourceName.${data.label}`)})`
                              : data.label)
                            : $t(`promotionAg.${data.label}`) }}
                        </span>
                      </div>
                    </template>
                    <template #option="data">
                      <div class="d-flex-center justify-content-between">
                        <span class=" d-block text-nowrap">
                          {{ data.value
                            ? ($te(`flight.airlines.${data.label}`)
                              ? `${$t(`flight.airlines.${data.label}`)} (${$t(`flight.sourceName.${data.label}`)})`
                              : data.label)
                            : $t(`promotionAg.${data.label}`) }}
                        </span>
                      </div>
                    </template>
                    <template #spinner="data">
                      <div
                        v-if="data.loading"
                        style="border-left-color: rgba(88, 151, 251, 0.71)"
                        class="vs__spinner"
                      />
                    </template>
                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- ANCHOR operator -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.operator') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotion.operator"
                    style="font-size: 1rem;"
                    multiple
                    push-tags
                    :close-on-select="false"
                    :taggable="enableTaggable"
                    :options="airlineFilterOptions"
                    :create-option="createValidAirlineOption"
                    :reduce="val => val.value"
                    :placeholder="$t('promotionAg.placeholder.operator')"
                    :disabled="readonly"
                    class="v-select-wrap"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        ref="refSearchOrInputCodeshare"
                        v-model="codeshare"
                        v-bind="attributes"
                        maxlength="2"
                        class="vs__search"
                        oninput="this.value = this.value.toUpperCase()"
                        v-on="events"
                        @keydown.enter="onEnterCodeshare()"
                      >
                    </template>
                    <template #selected-option="data">
                      <div class="d-flex-center justify-content-between">
                        <span
                          class="d-block text-nowrap"
                          :class="{ 'font-weight-bold text-dark': !allVnAirlines.includes(data.value) }"
                        >
                          {{ data.label }}
                        </span>
                      </div>
                    </template>
                    <template #option="data">
                      <div
                        class="d-flex-center justify-content-between"
                        :class="{ 'font-weight-bold text-dark': !allVnAirlines.includes(data.value) }"
                      >
                        <span class="d-block text-nowrap">
                          {{ data.label }}
                        </span>
                      </div>
                    </template>
                    <template #spinner="data">
                      <div
                        v-if="data.loading"
                        style="border-left-color: rgba(88, 151, 251, 0.71)"
                        class="vs__spinner"
                      />
                    </template>
                    <template #no-options>
                      {{ $t('promotionAg.notExistCodeshare') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- ANCHOR agencies apply -->
              <b-col md="4">
                <SearchAgenciesSelect
                  :label="'agencyApply'"
                  :placeholder="'promotionAg.allAgencies'"
                  :value="promotion.agencies"
                  :disabled="readonly"
                  @updateValue="val => { promotion.agencies = cloneDeep(val) }"
                />
              </b-col>

              <!-- ANCHOR agencies declined -->
              <b-col md="4">
                <SearchAgenciesSelect
                  :label="'agenciesDeclined'"
                  :placeholder="'placeholderSelect'"
                  :value="promotion.excludedAgencies"
                  :disabled="readonly"
                  @updateValue="val => { promotion.excludedAgencies = cloneDeep(val) }"
                />
              </b-col>
            </b-row>

            <b-row class="mt-50 mb-2">
              <b-col
                sm="12"
                style="border-top: 4px dotted silver;"
              />
            </b-row>

            <b-row>
              <!-- ANCHOR bookingClass -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.bookingClass') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotion.bookingClass"
                    style="font-size: 1rem;"
                    multiple
                    taggable
                    :close-on-select="false"
                    :placeholder="$t('promotionAg.placeholder.selectOrInput')"
                    :class="`v-select-wrap ${promotion.bookingClass?.length ? 'text-uppercase' : ''}`"
                    :reduce="val => val.toUpperCase()"
                    :disabled="readonly"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        :class="`vs__search ${promotion.bookingClass?.length || attributes.value ? 'text-uppercase' : ''}`"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                    <template #selected-option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #no-options>
                      {{ $t('promotionAg.placeholder.selectOrInput') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.groupClass') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotion.groupClass"
                    style="font-size: 1rem;"
                    multiple
                    taggable
                    :close-on-select="false"
                    :placeholder="$t('promotionAg.placeholder.selectOrInput')"
                    :class="`v-select-wrap ${promotion.groupClass?.length ? 'text-uppercase' : ''}`"
                    :reduce="val => val.toUpperCase()"
                    :disabled="readonly"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        :class="`vs__search ${promotion.groupClass?.length || attributes.value ? 'text-uppercase' : ''}`"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                    <template #selected-option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #no-options>
                      {{ $t('promotionAg.placeholder.selectOrInput') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- ANCHOR fareBasisCode -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.fareBasisCode') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotion.fareBasisCode"
                    style="font-size: 1rem;"
                    multiple
                    taggable
                    :close-on-select="false"
                    :placeholder="$t('promotionAg.placeholder.selectOrInput')"
                    :class="`v-select-wrap ${promotion.fareBasisCode?.length ? 'text-uppercase' : ''}`"
                    :reduce="val => val.toUpperCase()"
                    :disabled="readonly"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        :class="`vs__search ${promotion.fareBasisCode?.length || attributes.value ? 'text-uppercase' : ''}`"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                    <template #selected-option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #no-options>
                      {{ $t('promotionAg.placeholder.selectOrInput') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- ANCHOR Flight types -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.flightTypes') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotion.flightType"
                    style="font-size: 1rem;"
                    :options="flightTypeOptionsForPromotionAg"
                    :reduce="val => val.value"
                    :filterable="false"
                    :placeholder="$t('placeholderSelect')"
                    :disabled="readonly"
                  >
                    <template #selected-option="data">
                      <div class="d-flex-center justify-content-between">
                        <span class="d-block text-nowrap">
                          {{ $t(data.label) }}
                        </span>
                      </div>
                    </template>
                    <template #option="data">
                      <div class="d-flex-center justify-content-between">
                        <span class="d-block text-nowrap">
                          {{ $t(data.label) }}
                        </span>
                      </div>
                    </template>
                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- ANCHOR Flight start date -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.flightStartTime') }}
                    </div>
                  </template>
                  <b-form-datepicker
                    v-model="promotion.flightStartTime"
                    :locale="$i18n.locale"
                    :placeholder="$t('placeholderSelect')"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    :min="new Date()"
                    :max="promotion.flightEndTime ? new Date(promotion.flightEndTime) : null"
                    show-decade-nav
                    hide-header
                    boundary="window"
                    flip
                    :disabled="readonly"
                  />
                </b-form-group>
              </b-col>

              <!-- ANCHOR Flight end date -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.flightEndTime') }}
                    </div>
                  </template>
                  <b-form-datepicker
                    v-model="promotion.flightEndTime"
                    :locale="$i18n.locale"
                    :placeholder="$t('placeholderSelect')"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    :min="promotion.flightStartTime ? new Date(promotion.flightStartTime) : new Date()"
                    show-decade-nav
                    hide-header
                    boundary="window"
                    flip
                    :disabled="readonly"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-50 mb-2">
              <b-col
                sm="12"
                style="border-top: 4px dotted silver;"
              />
            </b-row>

            <!-- region  -->
            <b-row align-horizontally="start">
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.regionDepartures') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotion.regionDepartures"
                    style="font-size: 1rem;"
                    multiple
                    taggable
                    :close-on-select="false"
                    :placeholder="$t('promotionAg.placeholder.selectOrInput')"
                    :class="`v-select-wrap ${promotion.regionDepartures?.length ? 'text-uppercase' : ''}`"
                    :reduce="val => val.toUpperCase()"
                    :disabled="readonly"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        :class="`vs__search ${promotion.regionDepartures?.length || attributes.value ? 'text-uppercase' : ''}`"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                    <template #selected-option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #no-options>
                      {{ $t('promotionAg.placeholder.selectOrInput') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.regionArrivals') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotion.regionArrivals"
                    style="font-size: 1rem;"
                    multiple
                    taggable
                    :close-on-select="false"
                    :placeholder="$t('promotionAg.placeholder.selectOrInput')"
                    :class="`v-select-wrap ${promotion.regionArrivals?.length ? 'text-uppercase' : ''}`"
                    :reduce="val => val.toUpperCase()"
                    :disabled="readonly"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        :class="`vs__search ${promotion.regionArrivals?.length || attributes.value ? 'text-uppercase' : ''}`"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                    <template #selected-option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #no-options>
                      {{ $t('promotionAg.placeholder.selectOrInput') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <InputMultipleAutoTaggable
                  :label="'regionTrips'"
                  :placeholder="'promotionAg.placeholder.regionTrips'"
                  :valueLength="2"
                  :valueMaxLength="5"
                  :value="promotion.regionTrips"
                  :disabled="readonly"
                  @updateValue="val => { promotion.regionTrips = val }"
                />
              </b-col>
            </b-row>

            <!-- country -->
            <b-row align-horizontally="start">
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.countryDepartures') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotion.countryDepartures"
                    style="font-size: 1rem;"
                    multiple
                    taggable
                    :close-on-select="false"
                    :placeholder="$t('promotionAg.placeholder.selectOrInput')"
                    :class="`v-select-wrap ${promotion.countryDepartures?.length ? 'text-uppercase' : ''}`"
                    :reduce="val => val.toUpperCase()"
                    :disabled="readonly"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        :class="`vs__search ${promotion.countryDepartures?.length || attributes.value ? 'text-uppercase' : ''}`"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                    <template #selected-option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #no-options>
                      {{ $t('promotionAg.placeholder.selectOrInput') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.countryArrivals') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotion.countryArrivals"
                    style="font-size: 1rem;"
                    multiple
                    taggable
                    :close-on-select="false"
                    :placeholder="$t('promotionAg.placeholder.selectOrInput')"
                    :class="`v-select-wrap ${promotion.countryArrivals?.length ? 'text-uppercase' : ''}`"
                    :reduce="val => val.toUpperCase()"
                    :disabled="readonly"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        :class="`vs__search ${promotion.countryArrivals?.length || attributes.value ? 'text-uppercase' : ''}`"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                    <template #selected-option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #no-options>
                      {{ $t('promotionAg.placeholder.selectOrInput') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <InputMultipleAutoTaggable
                  :label="'countryTrips'"
                  :placeholder="'promotionAg.placeholder.countryTrips'"
                  :valueLength="2"
                  :value="promotion.countryTrips"
                  :valueMaxLength="5"
                  :disabled="readonly"
                  @updateValue="val => { promotion.countryTrips = val }"
                />
              </b-col>
            </b-row>

            <!-- flight -->
            <b-row align-horizontally="start">
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.flightDepartures') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotion.flightDepartures"
                    style="font-size: 1rem;"
                    multiple
                    taggable
                    :close-on-select="false"
                    :placeholder="$t('promotionAg.placeholder.selectOrInput')"
                    :class="`v-select-wrap ${promotion.flightDepartures?.length ? 'text-uppercase' : ''}`"
                    :reduce="val => val.toUpperCase()"
                    :disabled="readonly"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        :class="`vs__search ${promotion.flightDepartures?.length || attributes.value ? 'text-uppercase' : ''}`"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                    <template #selected-option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #no-options>
                      {{ $t('promotionAg.placeholder.selectOrInput') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.flightArrivals') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotion.flightArrivals"
                    style="font-size: 1rem;"
                    multiple
                    taggable
                    :close-on-select="false"
                    :placeholder="$t('promotionAg.placeholder.selectOrInput')"
                    :class="`v-select-wrap ${promotion.flightArrivals?.length ? 'text-uppercase' : ''}`"
                    :reduce="val => val.toUpperCase()"
                    :disabled="readonly"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        :class="`vs__search ${promotion.flightArrivals?.length || attributes.value ? 'text-uppercase' : ''}`"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                    <template #selected-option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #option="data">
                      <div class="text-uppercase">
                        {{ data.label }}
                      </div>
                    </template>
                    <template #no-options>
                      {{ $t('promotionAg.placeholder.selectOrInput') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <InputMultipleAutoTaggable
                  :label="'flightTrips'"
                  :placeholder="'promotionAg.placeholder.flightTrips'"
                  :valueLength="2"
                  :value="promotion.flightTrips"
                  :valueMaxLength="5"
                  :className="`mb-50 pb-0`"
                  :disabled="readonly"
                  @updateValue="val => { promotion.flightTrips = val }"
                />

                <div class="d-flex mb-50">
                  <div class="flex-1">
                    <b-form-checkbox
                      id="checkbox-soto"
                      v-model="isSOTO"
                      :disabled="readonly"
                    >
                      SOTO
                    </b-form-checkbox>
                  </div>
                  <div class="flex-1">
                    <b-form-checkbox
                      id="checkbox-siti"
                      v-model="isSITI"
                      :disabled="readonly"
                    >
                      SITI
                    </b-form-checkbox>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- ANCHOR Action Buttons -->
          <b-card-footer
            class="mt-50 pb-1"
            style="border-top: 4px dotted silver;"
          >
            <div class="d-flex gap-2 justify-content-center">
              <!-- Back button -->
              <b-button
                variant="secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="backHandle"
              >
                {{ $t('back') }}
              </b-button>

              <!-- Clear button -->
              <b-button
                v-if="!readonly && !!promotion.id"
                variant="danger"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="pristine"
                @click="clearHandle"
              >
                {{ $t('clear') }}
              </b-button>

              <!-- Next button -->
              <b-button
                v-if="!readonly"
                variant="info"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="invalid"
                type="submit"
              >
                {{ $t('save') }}
              </b-button>
            </div>
          </b-card-footer>
        </b-card>
        <!-- !SECTION -->
      </b-form>
    <!-- !SECTION -->
    </IAmOverlay>
  </validation-observer>
</template>

<script>
import { watch, ref, computed } from '@vue/composition-api'
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import moment from 'moment'

import {
  flightTypeOptionsForPromotionAg,
  typeOfDiscountOptions, typeOfDiscountPaidOptions,
  typeOfDiscountStrategyOptions,
} from '@/constants/selectOptions'
import store from '@/store'

import { getDifference } from '@core/utils/utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput, upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import usePromotionAgAirlines from '@promotionAg/usePromotionAgAirlines'
import usePromotionAgCodeshare from '@promotionAg/usePromotionAgCodeshare'
import usePromotionAgHandle from '@promotionAg/usePromotionAgHandle'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardFooter,
    BFormDatepicker,
    BFormCheckbox,
    BBadge,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    InputMultipleAutoTaggable: () => import('./InputMultipleAutoTaggable.vue'),
    SearchAgenciesSelect: () => import('./SearchAgenciesSelect.vue'),

  },
  props: {
    item: {
      type: Object,
      default: () => ({
        name: null,
        code: '',
        airline: [],
        source: [],
        discountAmount: null,
        discountPercent: null,
        discountType: 'VND',
        paidType: 'PREPAID',
        strategy: 'BY_CUSTOMER_SEGMENT', // 'BY_TICKET_NUMBER',
        flightTrips: [],
        flightType: null,
        startTime: null,
        endTime: null,
        flightStartTime: null,
        flightEndTime: null,
        agencies: [],
        minimumPassenger: null,
        operator: [],
        bookingClass: [],
        groupClass: [],
        fareBasisCode: [],
        excludedAgencies: [],

        regionDepartures: [],
        regionArrivals: [],
        regionTrips: [],
        countryDepartures: [],
        countryArrivals: [],
        countryTrips: [],
        flightDepartures: [],
        flightArrivals: [],
        flightDesignators: [],
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      allVnAirlines,
      airlineFilterOptions,
      airlineFilterOptionsWithAllAirlines,
      sourceFilterOptionsWithAllSources,
      createValidAirlineOption,
      loadingSources,
    } = usePromotionAgAirlines()
    const {
      updatePromotion,
      addPromotion,
    } = usePromotionAgHandle()
    const {
      codeshare,
      enableTaggable,
      onEnterCodeshare,
    } = usePromotionAgCodeshare()

    // var
    const readonly = ref(false)
    const isSOTO = ref(false)
    const isSITI = ref(false)
    const promotion = ref(cloneDeep(props.item))

    const resetData = () => {
      promotion.value = cloneDeep(props.item)
      isSOTO.value = props.item.isSOTO
      isSITI.value = props.item.isSITI
    }
    const meData = computed(() => store.getters['userStore/getMeData'])
    const canEdit = computed(() => {
      if (!promotion.value) return true
      const isType = ['OPE', 'ADM'].includes(meData.value?.type)
      const isStarted = moment().isAfter(moment(promotion.value.startTime))
      return isType && !isStarted
    })
    // Theo dõi thay đổi của props.item để cập nhật promotion khi cần
    watch(() => props.item, newItem => {
      promotion.value = cloneDeep(newItem)
      isSOTO.value = newItem.isSOTO
      isSITI.value = newItem.isSITI
      readonly.value = !!promotion.value.id
    })

    watch(isSOTO, () => {
      if (isSOTO.value) {
        promotion.value.flightTrips = promotion.value.flightTrips || []
        if (!promotion.value.flightTrips.includes('!VN-!XX')) {
          promotion.value.flightTrips.push('!VN-!XX')
        }
      } else {
        promotion.value.flightTrips = (promotion.value?.flightTrips ?? []).filter(i => i !== '!VN-!XX')
      }
    })

    watch(isSITI, () => {
      if (isSITI.value) {
        promotion.value.flightTrips = promotion.value.flightTrips || []
        if (!promotion.value.flightTrips.includes('VN-!VN')) {
          promotion.value.flightTrips.push('VN-!VN')
        }
      } else {
        promotion.value.flightTrips = promotion.value.flightTrips.filter(i => i !== 'VN-!VN')
      }
    })
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetData)

    // function
    function clearHandle() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.clearForm'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) resetForm()
        })
    }
    function backHandle() {
      this.$router.go(-1)
    }

    function nextHandle() {
      this.$bvModal.show('modal-api-loading');
      (promotion.value.id
        ? updatePromotion(promotion.value.id, getDifference(promotion.value, props.item))
        : addPromotion(promotion.value))
        .then(() => {
          this.$router.push({ name: 'apps-promotionAg-list' })
        })
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
        })
    }
    function handleChangeDiscountType(val) {
      if (val === 'VND') {
        promotion.value.discountAmount = promotion.value.discountPercent
      } else if (val === '%' && promotion.value.discountAmount >= 0 && promotion.value.discountAmount <= 100) {
        promotion.value.discountPercent = promotion.value.discountAmount
      } else {
        promotion.value.discountPercent = null
        promotion.value.discountAmount = null
      }
    }

    function handleEdit() {
      readonly.value = false
    }
    function handleCancelEdit() {
      readonly.value = true
      resetData()
    }
    // hook
    return {
      cloneDeep,
      backHandle,
      clearHandle,
      getValidationState,
      resetForm,
      promotion,
      handleChangeDiscountType,
      nextHandle,
      refFormObserver,

      // select options
      typeOfDiscountOptions,
      typeOfDiscountPaidOptions,
      flightTypeOptionsForPromotionAg,
      typeOfDiscountStrategyOptions,

      trimInput,
      upperCaseFormatter,
      allVnAirlines,
      airlineFilterOptions,
      airlineFilterOptionsWithAllAirlines,
      sourceFilterOptionsWithAllSources,
      createValidAirlineOption,
      loadingSources,

      codeshare,
      enableTaggable,
      onEnterCodeshare,

      isSITI,
      isSOTO,
      canEdit,
      readonly,
      handleEdit,
      handleCancelEdit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-wrap {
  .vs__selected-options {
    flex-wrap: wrap;
  }
}
</style>
