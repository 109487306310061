import { ref, computed } from '@vue/composition-api'

export default function usePromotionAgAirlines() {
  const codeshare = ref('')
  const enableTaggable = computed(() => codeshare.value.length === 2)

  function onEnterCodeshare() {
    this.$nextTick(() => {
      codeshare.value = ''
      this.$refs.refSearchOrInputCodeshare.value = ''
      this.$refs.refSearchOrInputCodeshare.focus()
    })
  }

  return {
    codeshare,
    enableTaggable,
    onEnterCodeshare,
  }
}
