var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$promotion$booking, _vm$promotion$groupCl, _vm$promotion$fareBas, _vm$promotion$regionD, _vm$promotion$regionA, _vm$promotion$country, _vm$promotion$country3, _vm$promotion$flightD, _vm$promotion$flightA;
        var handleSubmit = _ref.handleSubmit,
          invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('IAmOverlay', {
          attrs: {
            "loading": _vm.loading
          }
        }, [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.nextHandle);
            }
          }
        }, [_c('b-card', {
          staticClass: "border mt-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between w-100"
              }, [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(!_vm.promotion.id ? _vm.$t('promotionAg.create.title') : _vm.readonly ? _vm.$t('promotionAg.edit.title') : _vm.$t('promotionAg.edit.titleEdit')) + " "), _vm.promotion.code ? _c('b-badge', {
                staticClass: "py-50",
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v(" " + _vm._s(_vm.promotion.code) + " ")]) : _vm._e()], 1), _vm.readonly && !!_vm.promotion.id && _vm.canEdit ? _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "py-75",
                attrs: {
                  "variant": "outline-primary",
                  "disabled": !_vm.canAccess('customer.updateCustomer')
                },
                on: {
                  "click": _vm.handleEdit
                }
              }, [_c('feather-icon', {
                staticClass: "mr-50",
                attrs: {
                  "icon": "RefreshCwIcon"
                }
              }), _c('span', {
                staticClass: "align-middle"
              }, [_vm._v(_vm._s(_vm.$t('customer.edit')))])], 1) : _vm._e(), !_vm.readonly && !!_vm.promotion.id ? _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "py-75",
                attrs: {
                  "variant": "outline-danger",
                  "disabled": !_vm.canAccess('customer.updateCustomer')
                },
                on: {
                  "click": _vm.handleCancelEdit
                }
              }, [_c('feather-icon', {
                staticClass: "mr-50",
                attrs: {
                  "icon": "XIcon"
                }
              }), _c('span', {
                staticClass: "align-middle"
              }, [_vm._v(_vm._s(_vm.$t('customer.cancel')))])], 1) : _vm._e()], 1)];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0 pt-2"
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.name'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.name')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "maxlength": "100",
                  "lazy-formatter": "",
                  "disabled": _vm.readonly,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('promotionAg.placeholder.name'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.promotion.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotion, "name", $$v);
                  },
                  expression: "promotion.name"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.promotionType')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.typeOfDiscountPaidOptions,
            "clearable": false,
            "disabled": _vm.readonly,
            "reduce": function reduce(val) {
              return val.value;
            }
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("promotionAg.".concat(data.label))) + " ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("promotionAg.".concat(data.label))) + " ")])];
            }
          }], null, true),
          model: {
            value: _vm.promotion.paidType,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "paidType", $$v);
            },
            expression: "promotion.paidType"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.promotionStrategy')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.typeOfDiscountStrategyOptions,
            "clearable": false,
            "disabled": _vm.readonly,
            "label": "label",
            "reduce": function reduce(val) {
              return val.value;
            }
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("promotionAg.".concat(data.label))) + " ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("promotionAg.".concat(data.label))) + " ")])];
            }
          }], null, true),
          model: {
            value: _vm.promotion.strategy,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "strategy", $$v);
            },
            expression: "promotion.strategy"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-row', [_vm.promotion.discountType === 'VND' ? _c('b-col', {
          staticStyle: {
            "min-width": "20%"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.discount'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.discount')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "type": "number",
                  "number": "",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "30",
                  "disabled": _vm.readonly,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('promotionAg.placeholder.discountAmount')
                },
                model: {
                  value: _vm.promotion.discountAmount,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotion, "discountAmount", $$v);
                  },
                  expression: "promotion.discountAmount"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1) : _c('b-col', {
          staticStyle: {
            "min-width": "20%"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.discount'),
            "rules": "required|maxValue:100|min_value:0"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.discount')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "max": "100",
                  "type": "number",
                  "number": "",
                  "step": "0.01",
                  "min": "0",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "placeholder": _vm.$t('promotionAg.placeholder.discountPercent'),
                  "disabled": _vm.readonly,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput
                },
                model: {
                  value: _vm.promotion.discountPercent,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotion, "discountPercent", $$v);
                  },
                  expression: "promotion.discountPercent"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          staticClass: "pl-0",
          attrs: {
            "cols": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticStyle: {
                  "opacity": "0"
                }
              }, [_vm._v(" " + _vm._s("-") + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.typeOfDiscountOptions,
            "disabled": _vm.readonly,
            "clearable": false,
            "reduce": function reduce(val) {
              return val.value;
            }
          },
          on: {
            "input": _vm.handleChangeDiscountType
          },
          model: {
            value: _vm.promotion.discountType,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "discountType", $$v);
            },
            expression: "promotion.discountType"
          }
        })], 1)], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.startTime'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.startTime')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-datepicker', {
                attrs: {
                  "locale": _vm.$i18n.locale,
                  "placeholder": _vm.$t('placeholderSelect'),
                  "date-format-options": {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  },
                  "min": new Date(),
                  "max": _vm.promotion.endTime ? new Date(_vm.promotion.endTime) : null,
                  "disabled": _vm.readonly,
                  "show-decade-nav": "",
                  "hide-header": "",
                  "boundary": "window",
                  "no-flip": ""
                },
                model: {
                  value: _vm.promotion.startTime,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotion, "startTime", $$v);
                  },
                  expression: "promotion.startTime"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.endTime'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.endTime')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-datepicker', {
                attrs: {
                  "locale": _vm.$i18n.locale,
                  "placeholder": _vm.$t('placeholderSelect'),
                  "date-format-options": {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  },
                  "min": _vm.promotion.startTime ? new Date(_vm.promotion.startTime) : new Date(),
                  "show-decade-nav": "",
                  "hide-header": "",
                  "disabled": _vm.readonly,
                  "boundary": "window",
                  "no-flip": ""
                },
                model: {
                  value: _vm.promotion.endTime,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotion, "endTime", $$v);
                  },
                  expression: "promotion.endTime"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1), _c('b-row', {
          staticClass: "mt-50 mb-2"
        }, [_c('b-col', {
          staticStyle: {
            "border-top": "4px dotted silver"
          },
          attrs: {
            "sm": "12"
          }
        })], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.minimumPassenger'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.minimumPassenger')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "disabled": _vm.readonly,
                  "number": "",
                  "maxlength": "5",
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('promotionAg.placeholder.minimumPassenger'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.promotion.minimumPassenger,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotion, "minimumPassenger", $$v);
                  },
                  expression: "promotion.minimumPassenger"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.quotaAmount')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          directives: [{
            name: "remove-non-numeric-chars",
            rawName: "v-remove-non-numeric-chars.allNumber",
            modifiers: {
              "allNumber": true
            }
          }],
          attrs: {
            "number": "",
            "type": "number",
            "maxlength": "30",
            "lazy-formatter": "",
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('promotionAg.placeholder.quotaAmount'),
            "disabled": _vm.readonly
          },
          model: {
            value: _vm.promotion.quotaAmount,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "quotaAmount", $$v);
            },
            expression: "promotion.quotaAmount"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.quotaCounter')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          directives: [{
            name: "remove-non-numeric-chars",
            rawName: "v-remove-non-numeric-chars.allNumber",
            modifiers: {
              "allNumber": true
            }
          }],
          attrs: {
            "type": "number",
            "number": "",
            "maxlength": "30",
            "lazy-formatter": "",
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('promotionAg.placeholder.quotaCounter'),
            "disabled": _vm.readonly
          },
          model: {
            value: _vm.promotion.quotaCounter,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "quotaCounter", $$v);
            },
            expression: "promotion.quotaCounter"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.airline')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "v-select-wrap",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.airlineFilterOptionsWithAllAirlines,
            "multiple": "",
            "close-on-select": false,
            "reduce": function reduce(val) {
              return val.value;
            },
            "placeholder": _vm.$t('promotionAg.allAirlines'),
            "disabled": _vm.readonly
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.value ? data.label : _vm.$t("promotionAg.".concat(data.label))) + " ")])])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: " d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.value ? data.label : _vm.$t("promotionAg.".concat(data.label))) + " ")])])];
            }
          }, {
            key: "spinner",
            fn: function fn(data) {
              return [data.loading ? _c('div', {
                staticClass: "vs__spinner",
                staticStyle: {
                  "border-left-color": "rgba(88, 151, 251, 0.71)"
                }
              }) : _vm._e()];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotion.airline,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "airline", $$v);
            },
            expression: "promotion.airline"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.source')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "v-select-wrap",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.sourceFilterOptionsWithAllSources,
            "multiple": "",
            "close-on-select": false,
            "reduce": function reduce(val) {
              return val.value;
            },
            "loading": _vm.loadingSources,
            "placeholder": _vm.$t('promotionAg.allAirlines'),
            "disabled": _vm.readonly
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.value ? _vm.$te("flight.airlines.".concat(data.label)) ? "".concat(_vm.$t("flight.airlines.".concat(data.label)), " (").concat(_vm.$t("flight.sourceName.".concat(data.label)), ")") : data.label : _vm.$t("promotionAg.".concat(data.label))) + " ")])])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: " d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.value ? _vm.$te("flight.airlines.".concat(data.label)) ? "".concat(_vm.$t("flight.airlines.".concat(data.label)), " (").concat(_vm.$t("flight.sourceName.".concat(data.label)), ")") : data.label : _vm.$t("promotionAg.".concat(data.label))) + " ")])])];
            }
          }, {
            key: "spinner",
            fn: function fn(data) {
              return [data.loading ? _c('div', {
                staticClass: "vs__spinner",
                staticStyle: {
                  "border-left-color": "rgba(88, 151, 251, 0.71)"
                }
              }) : _vm._e()];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotion.source,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "source", $$v);
            },
            expression: "promotion.source"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.operator')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "v-select-wrap",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "push-tags": "",
            "close-on-select": false,
            "taggable": _vm.enableTaggable,
            "options": _vm.airlineFilterOptions,
            "create-option": _vm.createValidAirlineOption,
            "reduce": function reduce(val) {
              return val.value;
            },
            "placeholder": _vm.$t('promotionAg.placeholder.operator'),
            "disabled": _vm.readonly
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref2) {
              var attributes = _ref2.attributes,
                events = _ref2.events;
              return [attributes.type === 'checkbox' ? _c('input', _vm._g(_vm._b({
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.codeshare,
                  expression: "codeshare"
                }],
                ref: "refSearchOrInputCodeshare",
                staticClass: "vs__search",
                attrs: {
                  "maxlength": "2",
                  "oninput": "this.value = this.value.toUpperCase()",
                  "type": "checkbox"
                },
                domProps: {
                  "checked": Array.isArray(_vm.codeshare) ? _vm._i(_vm.codeshare, null) > -1 : _vm.codeshare
                },
                on: {
                  "keydown": function keydown($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    return _vm.onEnterCodeshare();
                  },
                  "change": function change($event) {
                    var $$a = _vm.codeshare,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && (_vm.codeshare = $$a.concat([$$v]));
                      } else {
                        $$i > -1 && (_vm.codeshare = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.codeshare = $$c;
                    }
                  }
                }
              }, 'input', attributes, false), events)) : attributes.type === 'radio' ? _c('input', _vm._g(_vm._b({
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.codeshare,
                  expression: "codeshare"
                }],
                ref: "refSearchOrInputCodeshare",
                staticClass: "vs__search",
                attrs: {
                  "maxlength": "2",
                  "oninput": "this.value = this.value.toUpperCase()",
                  "type": "radio"
                },
                domProps: {
                  "checked": _vm._q(_vm.codeshare, null)
                },
                on: {
                  "keydown": function keydown($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    return _vm.onEnterCodeshare();
                  },
                  "change": function change($event) {
                    _vm.codeshare = null;
                  }
                }
              }, 'input', attributes, false), events)) : _c('input', _vm._g(_vm._b({
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.codeshare,
                  expression: "codeshare"
                }],
                ref: "refSearchOrInputCodeshare",
                staticClass: "vs__search",
                attrs: {
                  "maxlength": "2",
                  "oninput": "this.value = this.value.toUpperCase()",
                  "type": attributes.type
                },
                domProps: {
                  "value": _vm.codeshare
                },
                on: {
                  "keydown": function keydown($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    return _vm.onEnterCodeshare();
                  },
                  "input": function input($event) {
                    if ($event.target.composing) { return; }
                    _vm.codeshare = $event.target.value;
                  }
                }
              }, 'input', attributes, false), events))];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap",
                class: {
                  'font-weight-bold text-dark': !_vm.allVnAirlines.includes(data.value)
                }
              }, [_vm._v(" " + _vm._s(data.label) + " ")])])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between",
                class: {
                  'font-weight-bold text-dark': !_vm.allVnAirlines.includes(data.value)
                }
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])])];
            }
          }, {
            key: "spinner",
            fn: function fn(data) {
              return [data.loading ? _c('div', {
                staticClass: "vs__spinner",
                staticStyle: {
                  "border-left-color": "rgba(88, 151, 251, 0.71)"
                }
              }) : _vm._e()];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.notExistCodeshare')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotion.operator,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "operator", $$v);
            },
            expression: "promotion.operator"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('SearchAgenciesSelect', {
          attrs: {
            "label": 'agencyApply',
            "placeholder": 'promotionAg.allAgencies',
            "value": _vm.promotion.agencies,
            "disabled": _vm.readonly
          },
          on: {
            "updateValue": function updateValue(val) {
              _vm.promotion.agencies = _vm.cloneDeep(val);
            }
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('SearchAgenciesSelect', {
          attrs: {
            "label": 'agenciesDeclined',
            "placeholder": 'placeholderSelect',
            "value": _vm.promotion.excludedAgencies,
            "disabled": _vm.readonly
          },
          on: {
            "updateValue": function updateValue(val) {
              _vm.promotion.excludedAgencies = _vm.cloneDeep(val);
            }
          }
        })], 1)], 1), _c('b-row', {
          staticClass: "mt-50 mb-2"
        }, [_c('b-col', {
          staticStyle: {
            "border-top": "4px dotted silver"
          },
          attrs: {
            "sm": "12"
          }
        })], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.bookingClass')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          class: "v-select-wrap ".concat((_vm$promotion$booking = _vm.promotion.bookingClass) !== null && _vm$promotion$booking !== void 0 && _vm$promotion$booking.length ? 'text-uppercase' : ''),
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "taggable": "",
            "close-on-select": false,
            "placeholder": _vm.$t('promotionAg.placeholder.selectOrInput'),
            "reduce": function reduce(val) {
              return val.toUpperCase();
            },
            "disabled": _vm.readonly
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref3) {
              var _vm$promotion$booking2;
              var attributes = _ref3.attributes,
                events = _ref3.events;
              return [_c('input', _vm._g(_vm._b({
                class: "vs__search ".concat((_vm$promotion$booking2 = _vm.promotion.bookingClass) !== null && _vm$promotion$booking2 !== void 0 && _vm$promotion$booking2.length || attributes.value ? 'text-uppercase' : '')
              }, 'input', attributes, false), events))];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.placeholder.selectOrInput')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotion.bookingClass,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "bookingClass", $$v);
            },
            expression: "promotion.bookingClass"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.groupClass')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          class: "v-select-wrap ".concat((_vm$promotion$groupCl = _vm.promotion.groupClass) !== null && _vm$promotion$groupCl !== void 0 && _vm$promotion$groupCl.length ? 'text-uppercase' : ''),
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "taggable": "",
            "close-on-select": false,
            "placeholder": _vm.$t('promotionAg.placeholder.selectOrInput'),
            "reduce": function reduce(val) {
              return val.toUpperCase();
            },
            "disabled": _vm.readonly
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref4) {
              var _vm$promotion$groupCl2;
              var attributes = _ref4.attributes,
                events = _ref4.events;
              return [_c('input', _vm._g(_vm._b({
                class: "vs__search ".concat((_vm$promotion$groupCl2 = _vm.promotion.groupClass) !== null && _vm$promotion$groupCl2 !== void 0 && _vm$promotion$groupCl2.length || attributes.value ? 'text-uppercase' : '')
              }, 'input', attributes, false), events))];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.placeholder.selectOrInput')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotion.groupClass,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "groupClass", $$v);
            },
            expression: "promotion.groupClass"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.fareBasisCode')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          class: "v-select-wrap ".concat((_vm$promotion$fareBas = _vm.promotion.fareBasisCode) !== null && _vm$promotion$fareBas !== void 0 && _vm$promotion$fareBas.length ? 'text-uppercase' : ''),
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "taggable": "",
            "close-on-select": false,
            "placeholder": _vm.$t('promotionAg.placeholder.selectOrInput'),
            "reduce": function reduce(val) {
              return val.toUpperCase();
            },
            "disabled": _vm.readonly
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref5) {
              var _vm$promotion$fareBas2;
              var attributes = _ref5.attributes,
                events = _ref5.events;
              return [_c('input', _vm._g(_vm._b({
                class: "vs__search ".concat((_vm$promotion$fareBas2 = _vm.promotion.fareBasisCode) !== null && _vm$promotion$fareBas2 !== void 0 && _vm$promotion$fareBas2.length || attributes.value ? 'text-uppercase' : '')
              }, 'input', attributes, false), events))];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.placeholder.selectOrInput')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotion.fareBasisCode,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "fareBasisCode", $$v);
            },
            expression: "promotion.fareBasisCode"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.flightTypes')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.flightTypeOptionsForPromotionAg,
            "reduce": function reduce(val) {
              return val.value;
            },
            "filterable": false,
            "placeholder": _vm.$t('placeholderSelect'),
            "disabled": _vm.readonly
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotion.flightType,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "flightType", $$v);
            },
            expression: "promotion.flightType"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.flightStartTime')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-datepicker', {
          attrs: {
            "locale": _vm.$i18n.locale,
            "placeholder": _vm.$t('placeholderSelect'),
            "date-format-options": {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            },
            "min": new Date(),
            "max": _vm.promotion.flightEndTime ? new Date(_vm.promotion.flightEndTime) : null,
            "show-decade-nav": "",
            "hide-header": "",
            "boundary": "window",
            "flip": "",
            "disabled": _vm.readonly
          },
          model: {
            value: _vm.promotion.flightStartTime,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "flightStartTime", $$v);
            },
            expression: "promotion.flightStartTime"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.flightEndTime')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-datepicker', {
          attrs: {
            "locale": _vm.$i18n.locale,
            "placeholder": _vm.$t('placeholderSelect'),
            "date-format-options": {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            },
            "min": _vm.promotion.flightStartTime ? new Date(_vm.promotion.flightStartTime) : new Date(),
            "show-decade-nav": "",
            "hide-header": "",
            "boundary": "window",
            "flip": "",
            "disabled": _vm.readonly
          },
          model: {
            value: _vm.promotion.flightEndTime,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "flightEndTime", $$v);
            },
            expression: "promotion.flightEndTime"
          }
        })], 1)], 1)], 1), _c('b-row', {
          staticClass: "mt-50 mb-2"
        }, [_c('b-col', {
          staticStyle: {
            "border-top": "4px dotted silver"
          },
          attrs: {
            "sm": "12"
          }
        })], 1), _c('b-row', {
          attrs: {
            "align-horizontally": "start"
          }
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.regionDepartures')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          class: "v-select-wrap ".concat((_vm$promotion$regionD = _vm.promotion.regionDepartures) !== null && _vm$promotion$regionD !== void 0 && _vm$promotion$regionD.length ? 'text-uppercase' : ''),
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "taggable": "",
            "close-on-select": false,
            "placeholder": _vm.$t('promotionAg.placeholder.selectOrInput'),
            "reduce": function reduce(val) {
              return val.toUpperCase();
            },
            "disabled": _vm.readonly
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref6) {
              var _vm$promotion$regionD2;
              var attributes = _ref6.attributes,
                events = _ref6.events;
              return [_c('input', _vm._g(_vm._b({
                class: "vs__search ".concat((_vm$promotion$regionD2 = _vm.promotion.regionDepartures) !== null && _vm$promotion$regionD2 !== void 0 && _vm$promotion$regionD2.length || attributes.value ? 'text-uppercase' : '')
              }, 'input', attributes, false), events))];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.placeholder.selectOrInput')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotion.regionDepartures,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "regionDepartures", $$v);
            },
            expression: "promotion.regionDepartures"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.regionArrivals')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          class: "v-select-wrap ".concat((_vm$promotion$regionA = _vm.promotion.regionArrivals) !== null && _vm$promotion$regionA !== void 0 && _vm$promotion$regionA.length ? 'text-uppercase' : ''),
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "taggable": "",
            "close-on-select": false,
            "placeholder": _vm.$t('promotionAg.placeholder.selectOrInput'),
            "reduce": function reduce(val) {
              return val.toUpperCase();
            },
            "disabled": _vm.readonly
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref7) {
              var _vm$promotion$regionA2;
              var attributes = _ref7.attributes,
                events = _ref7.events;
              return [_c('input', _vm._g(_vm._b({
                class: "vs__search ".concat((_vm$promotion$regionA2 = _vm.promotion.regionArrivals) !== null && _vm$promotion$regionA2 !== void 0 && _vm$promotion$regionA2.length || attributes.value ? 'text-uppercase' : '')
              }, 'input', attributes, false), events))];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.placeholder.selectOrInput')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotion.regionArrivals,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "regionArrivals", $$v);
            },
            expression: "promotion.regionArrivals"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('InputMultipleAutoTaggable', {
          attrs: {
            "label": 'regionTrips',
            "placeholder": 'promotionAg.placeholder.regionTrips',
            "valueLength": 2,
            "valueMaxLength": 5,
            "value": _vm.promotion.regionTrips,
            "disabled": _vm.readonly
          },
          on: {
            "updateValue": function updateValue(val) {
              _vm.promotion.regionTrips = val;
            }
          }
        })], 1)], 1), _c('b-row', {
          attrs: {
            "align-horizontally": "start"
          }
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.countryDepartures')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          class: "v-select-wrap ".concat((_vm$promotion$country = _vm.promotion.countryDepartures) !== null && _vm$promotion$country !== void 0 && _vm$promotion$country.length ? 'text-uppercase' : ''),
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "taggable": "",
            "close-on-select": false,
            "placeholder": _vm.$t('promotionAg.placeholder.selectOrInput'),
            "reduce": function reduce(val) {
              return val.toUpperCase();
            },
            "disabled": _vm.readonly
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref8) {
              var _vm$promotion$country2;
              var attributes = _ref8.attributes,
                events = _ref8.events;
              return [_c('input', _vm._g(_vm._b({
                class: "vs__search ".concat((_vm$promotion$country2 = _vm.promotion.countryDepartures) !== null && _vm$promotion$country2 !== void 0 && _vm$promotion$country2.length || attributes.value ? 'text-uppercase' : '')
              }, 'input', attributes, false), events))];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.placeholder.selectOrInput')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotion.countryDepartures,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "countryDepartures", $$v);
            },
            expression: "promotion.countryDepartures"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.countryArrivals')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          class: "v-select-wrap ".concat((_vm$promotion$country3 = _vm.promotion.countryArrivals) !== null && _vm$promotion$country3 !== void 0 && _vm$promotion$country3.length ? 'text-uppercase' : ''),
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "taggable": "",
            "close-on-select": false,
            "placeholder": _vm.$t('promotionAg.placeholder.selectOrInput'),
            "reduce": function reduce(val) {
              return val.toUpperCase();
            },
            "disabled": _vm.readonly
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref9) {
              var _vm$promotion$country4;
              var attributes = _ref9.attributes,
                events = _ref9.events;
              return [_c('input', _vm._g(_vm._b({
                class: "vs__search ".concat((_vm$promotion$country4 = _vm.promotion.countryArrivals) !== null && _vm$promotion$country4 !== void 0 && _vm$promotion$country4.length || attributes.value ? 'text-uppercase' : '')
              }, 'input', attributes, false), events))];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.placeholder.selectOrInput')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotion.countryArrivals,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "countryArrivals", $$v);
            },
            expression: "promotion.countryArrivals"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('InputMultipleAutoTaggable', {
          attrs: {
            "label": 'countryTrips',
            "placeholder": 'promotionAg.placeholder.countryTrips',
            "valueLength": 2,
            "value": _vm.promotion.countryTrips,
            "valueMaxLength": 5,
            "disabled": _vm.readonly
          },
          on: {
            "updateValue": function updateValue(val) {
              _vm.promotion.countryTrips = val;
            }
          }
        })], 1)], 1), _c('b-row', {
          attrs: {
            "align-horizontally": "start"
          }
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.flightDepartures')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          class: "v-select-wrap ".concat((_vm$promotion$flightD = _vm.promotion.flightDepartures) !== null && _vm$promotion$flightD !== void 0 && _vm$promotion$flightD.length ? 'text-uppercase' : ''),
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "taggable": "",
            "close-on-select": false,
            "placeholder": _vm.$t('promotionAg.placeholder.selectOrInput'),
            "reduce": function reduce(val) {
              return val.toUpperCase();
            },
            "disabled": _vm.readonly
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref10) {
              var _vm$promotion$flightD2;
              var attributes = _ref10.attributes,
                events = _ref10.events;
              return [_c('input', _vm._g(_vm._b({
                class: "vs__search ".concat((_vm$promotion$flightD2 = _vm.promotion.flightDepartures) !== null && _vm$promotion$flightD2 !== void 0 && _vm$promotion$flightD2.length || attributes.value ? 'text-uppercase' : '')
              }, 'input', attributes, false), events))];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.placeholder.selectOrInput')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotion.flightDepartures,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "flightDepartures", $$v);
            },
            expression: "promotion.flightDepartures"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.flightArrivals')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          class: "v-select-wrap ".concat((_vm$promotion$flightA = _vm.promotion.flightArrivals) !== null && _vm$promotion$flightA !== void 0 && _vm$promotion$flightA.length ? 'text-uppercase' : ''),
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "taggable": "",
            "close-on-select": false,
            "placeholder": _vm.$t('promotionAg.placeholder.selectOrInput'),
            "reduce": function reduce(val) {
              return val.toUpperCase();
            },
            "disabled": _vm.readonly
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref11) {
              var _vm$promotion$flightA2;
              var attributes = _ref11.attributes,
                events = _ref11.events;
              return [_c('input', _vm._g(_vm._b({
                class: "vs__search ".concat((_vm$promotion$flightA2 = _vm.promotion.flightArrivals) !== null && _vm$promotion$flightA2 !== void 0 && _vm$promotion$flightA2.length || attributes.value ? 'text-uppercase' : '')
              }, 'input', attributes, false), events))];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-uppercase"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.placeholder.selectOrInput')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotion.flightArrivals,
            callback: function callback($$v) {
              _vm.$set(_vm.promotion, "flightArrivals", $$v);
            },
            expression: "promotion.flightArrivals"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('InputMultipleAutoTaggable', {
          attrs: {
            "label": 'flightTrips',
            "placeholder": 'promotionAg.placeholder.flightTrips',
            "valueLength": 2,
            "value": _vm.promotion.flightTrips,
            "valueMaxLength": 5,
            "className": "mb-50 pb-0",
            "disabled": _vm.readonly
          },
          on: {
            "updateValue": function updateValue(val) {
              _vm.promotion.flightTrips = val;
            }
          }
        }), _c('div', {
          staticClass: "d-flex mb-50"
        }, [_c('div', {
          staticClass: "flex-1"
        }, [_c('b-form-checkbox', {
          attrs: {
            "id": "checkbox-soto",
            "disabled": _vm.readonly
          },
          model: {
            value: _vm.isSOTO,
            callback: function callback($$v) {
              _vm.isSOTO = $$v;
            },
            expression: "isSOTO"
          }
        }, [_vm._v(" SOTO ")])], 1), _c('div', {
          staticClass: "flex-1"
        }, [_c('b-form-checkbox', {
          attrs: {
            "id": "checkbox-siti",
            "disabled": _vm.readonly
          },
          model: {
            value: _vm.isSITI,
            callback: function callback($$v) {
              _vm.isSITI = $$v;
            },
            expression: "isSITI"
          }
        }, [_vm._v(" SITI ")])], 1)])], 1)], 1)], 1), _c('b-card-footer', {
          staticClass: "mt-50 pb-1",
          staticStyle: {
            "border-top": "4px dotted silver"
          }
        }, [_c('div', {
          staticClass: "d-flex gap-2 justify-content-center"
        }, [_c('b-button', {
          attrs: {
            "variant": "secondary",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
          },
          on: {
            "click": _vm.backHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('back')) + " ")]), !_vm.readonly && !!_vm.promotion.id ? _c('b-button', {
          attrs: {
            "variant": "danger",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs',
            "disabled": pristine
          },
          on: {
            "click": _vm.clearHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('clear')) + " ")]) : _vm._e(), !_vm.readonly ? _c('b-button', {
          attrs: {
            "variant": "info",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs',
            "disabled": invalid,
            "type": "submit"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")]) : _vm._e()], 1)])], 1)], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }